import axios from "axios";

export const ACD_DOMAIN = window.location.origin.replace("3000", "4300");

export const OAUTH_DOMAIN = ACD_DOMAIN.replace("4300", "4700").replace("techrise", "oauth");
export const DOC_DOMAIN = ACD_DOMAIN.replace("4300", "5100").replace("techrise", "doc");

const CLIENT_ID_EXP_IN_HRS = 24;

export async function getClientId() {
  let clientIdWithTime = localStorage.getItem("clientId");
  let clientId = clientIdWithTime?.split("|")[0];
  let timestamp = clientIdWithTime?.split("|")[1];

  if (!clientId || !timestamp) {
    clientId = await fetchClientId();
    localStorage.setItem("clientId", `${clientId}|${Date.now()}}`);
  }

  // check if client id has expired
  if (timestamp) {
    const diff = Date.now() - +timestamp;
    if (diff > CLIENT_ID_EXP_IN_HRS * 60 * 60 * 1000) {
      clientId = await fetchClientId();
      localStorage.setItem("clientId", `${clientId}|${Date.now()}`);
    }
  }

  return clientId;
}

async function fetchClientId(): Promise<string> {
  const url = ACD_DOMAIN + "/init-data";
  const res = await axios.get(url);
  const resData = res.data;
  const clientId = resData.clientId;
  if (!clientId) throw new Error("Client Id not found in init data. Endpoint: " + url);
  return clientId;
}
